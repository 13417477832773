import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';

const bcRoutes: Routes = [
  {path: 'common', loadChildren: () => import('bcSharedComponents').then(m => m.BcCommonModule)},
  { path: '', loadChildren: () => import('./seriennummer-suche/seriennummer-suche.module').then(m => m.SeriennummerSucheModule) },
  { path: 'suche', loadChildren: () => import('./seriennummer-suche/seriennummer-suche.module').then(m => m.SeriennummerSucheModule)},
  { path: 'dguv-protocol', loadChildren: () => import('./dguv-protocol/dguv-protocol.module').then(m => m.DguvProtocolModule) },
  { path: 'kontakt', loadChildren: () => import('./kontakt/kontakt.module').then(m => m.KontaktModule) }, 
  { path: 'serialnumber-help', loadChildren: () => import('./serialnumber-help/serialnumber-help-module/serialnumber-help.module').then(m => m.SerialnumberHelpModule) },
  { path: 'herstellerservice', loadChildren: () => import('./herstellerservice/herstellerservice-module.module').then(m => m.HerstellerserviceModuleModule) },
  { path: 'system-information', loadChildren: () => import('./system-information/system-information-module.module').then(m => m.SystemInformationModuleModule)},
  { path: 'rma', loadChildren: () => import('./rma/rma-module.module').then(m => m.RmaModuleModule) },
  { path: 'vorortstoerfall', loadChildren: () => import('./vorortstoerfall/vorortstoerfall.module').then(m => m.VorortstoerfallModule) },
  { path: 'support', loadChildren: () => import('./support/support.module').then(m => m.SupportModule) },
  { path: 'admin', loadChildren: () => import('./admin-rma-table/admin-rma-table.module').then(m=> m.AdminRmaTableModule)},
  { path: 'vorort', loadChildren: () => import('./Vorort/vorort.module').then(m=> m.VorortModule) },
  { path: 'vorabtausch', loadChildren: () => import('./vorabtausch/vorabtausch.module').then(m=> m.VorabtauschModule) },
  { path: 'servicevereinbarungen', loadChildren: () => import('./servicevereinbarungen/servicevereinbarungen.module').then(m=> m.ServicevereinbarungenModule) },
  { path: 'dataprotection', loadChildren: () => import('../../../bc-shared-components/src/lib/privacy-notice/privacy-notice.module').then(m => m.PrivacyNoticeModule)},
  { path: 'softwaredl', loadChildren: () => import('./downloads/download.module').then(m => m.DownloadModule)},
  { path: 'vosmap', loadChildren: () => import('./vos-map/vos-map.module').then(m => m.VosMapModule)},
  { path: 'nachruestung', loadChildren: () => import('./cgm-nachruestung/cgm-nachruestung.module').then(m => m.CgmNachruestungModule)},
];

const ectRoutes: Routes = [
  { path: '', loadChildren: () => import('./seriennummer-suche-ect/suche-ect.module').then(m => m.SucheECTModule) },
  { path: 'system-information', loadChildren: () => import('./system-information/system-information-module.module').then(m => m.SystemInformationModuleModule) },
];

function  getRoute()
{
  const isPcSnOne = window.location.href.includes(environment.ectServiceUrl); // Liegt Hier:C:\Windows\System32\drivers\etc
 // console.log(environment.ectServiceUrl);
  return isPcSnOne ? ectRoutes : bcRoutes;
}
@NgModule({
  imports: [RouterModule.forRoot(getRoute())],
  exports: [RouterModule]
})
export class AppRoutingModule {}
